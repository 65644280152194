// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyABMgrbkgWCpZHFXvwdqEUXy2WbIu7NFog",
    authDomain: "petit-cactus-qa.firebaseapp.com",
    projectId: "petit-cactus-qa",
    storageBucket: "petit-cactus-qa.appspot.com",
    messagingSenderId: "929843023655",
    appId: "1:929843023655:web:538d2df6e00a21dd8ae302",
    measurementId: "G-R4F66JVQZ1"
  },
  apiKey: {
    googleTranslate: 'AIzaSyABMgrbkgWCpZHFXvwdqEUXy2WbIu7NFog'
  },
  alogliaIndex: 'petit-cactus-qa',
  algolia:{
    appId: 'VIOA2LAX1Y',
    apiKey: 'a3ed7b90b26153516c2db31bc05fac14',
  },
  production: false,
  mediaManagerUrl: 'https://s3-media-manager.petitcactus.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
