import { Timestamp } from 'firebase/firestore';

//enum listing all genders
export enum Gender {
  MALE,
  FEMALE,
  OTHER,
}

export enum Language {
  FRENCH = 'fr-CA',
  ENGLISH = 'en-CA',
}

export enum TreatmentMode {
  NONE = 0,
  INSULIN_PUMP = 1,
  INSULIN_INJECTION = 2,
}

export enum DiabetesType {
  NONE = 0,
  TYPE_1 = 1,
  TYPE_2 = 2,
  LADA = 3,
  GESTIATIONAL = 4,
}

export interface DiabetesProfile {
  continuousGlucoseMonitoring: boolean;
  insulinPump: boolean;
  treatmentMode: TreatmentMode;
  diabetesType: DiabetesType;
  correctionFactor: number;
  minTarget: number;
  target: number;
  maxTarget: number;
  penType: 0.5 | 1;
}

export enum RatioMeasure {
  X_CARB_BY_1_UNIT = 0,
  X_UNIT_BY_10G_CARBS = 1
}

export interface Ratio {
  name: string;
  ratio: number;
  unit: RatioMeasure;
  minTime: Timestamp;
  maxTime: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  birthDate: Timestamp;
  gender: Gender;
  language: Language;
  diabetes_profile?: DiabetesProfile;
  ratios?: Ratio[];
  onBoardingComplete?: boolean;
  cactusGptEnabled: boolean;
  favorites?: string[];
}
