import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'personal-information',
    loadChildren: () =>
      import('./pages/personal-information/personal-information.module').then(
        (m) => m.PersonalInformationPageModule
      ),
  },
  {
    path: 'ratios',
    loadChildren: () =>
      import('./pages/ratios/ratios.module').then((m) => m.RatiosPageModule),
  },
  {
    path: 'glycemic-profile',
    loadChildren: () =>
      import('./pages/glycemic-profile/glycemic-profile.module').then(
        (m) => m.GlycemicProfilePageModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'change-name',
    loadChildren: () =>
      import('./pages/change-name/change-name.module').then(
        (m) => m.ChangeNamePageModule
      ),
  },
  {
    path: 'create-food-journal',
    loadChildren: () =>
      import('./pages/create-food-journal/create-food-journal.module').then(
        (m) => m.CreateFoodJournalPageModule
      ),
  },
  {
    path: 'search-food',
    loadChildren: () =>
      import('./pages/search-food/search-food.module').then(
        (m) => m.SearchFoodPageModule
      ),
  },
  {
    path: 'food-detail',
    loadChildren: () =>
      import('./pages/food-detail/food-detail.module').then(
        (m) => m.FoodDetailPageModule
      ),
  },
  {
    path: 'add-edit-ratio/:param',
    loadChildren: () =>
      import('./pages/add-edit-ratio/add-edit-ratio.module').then(
        (m) => m.AddEditRatioPageModule
      ),
  },
  {
    path: 'gestational-diabetes',
    loadChildren: () => import('./pages/gestational-diabetes/gestational-diabetes.module').then(m => m.GestationalDiabetesPageModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'prefix',
    redirectTo: '',
  },
  {
    path: 'cactus-gpt',
    loadChildren: () => import('./pages/cactus-gpt/cactus-gpt.module').then( m => m.CactusGPTPageModule)
  },
  {
    path: 'food-picture-analysis',
    loadChildren: () => import('./pages/food-picture-analysis/food-picture-analysis.module').then( m => m.FoodPictureAnalysisPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
