import { FirebaseService } from './services/firebase/firebase.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user/user.service';
import { Auth, onAuthStateChanged } from 'firebase/auth';
import * as moment from 'moment';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('fr-CA');
    moment.locale('fr');
  }
}
