import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { onAuthStateChanged } from 'firebase/auth';
import { Observable } from 'rxjs';
import { FirebaseService } from '../services/firebase/firebase.service';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private firebaseService: FirebaseService, private router: Router, private userService: UserService, private translation: TranslateService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve, reject) => {
      const auth = this.firebaseService.getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        unsubscribe();
        let routeTree = state.url.split('/');
        if (user) {
          let userDoc = await this.userService.getUser(user.uid);
          this.translation.use(userDoc.language ?? 'fr-CA');

          if (!userDoc.onBoardingComplete && routeTree.filter((url) => url === 'glycemic-profile').length === 0) {
            this.router.navigate(['tabs', 'profile', 'glycemic-profile']);
            resolve(false);
          }

          if (routeTree.filter((url) => url === 'login' || url === 'register').length > 0) {
            this.router.navigate(['tabs', 'dashboard']);
            resolve(false);
          }

          resolve(true);
        } else {
          if (routeTree.filter((url) => url === 'login' || url === 'register').length > 0) {
            this.translation.use('fr-CA');
            resolve(true);
          } else {
            this.router.navigate(['/login']);
            this.translation.use('fr-CA');
            resolve(false);
          }
        }
      });
    });
  }
}
