import { Injectable } from '@angular/core';
import { Auth, browserLocalPersistence, onAuthStateChanged, setPersistence } from 'firebase/auth';
import {
  arrayUnion,
  collection,
  CollectionReference,
  deleteField,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import {
  DiabetesType,
  Gender,
  Language,
  Ratio,
  TreatmentMode,
  User,
} from 'src/app/types';
import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  collectionName = 'users';
  coll: CollectionReference<DocumentData>;
  firestore: Firestore;
  auth: Auth;

  // for a language to be recognized from the database it needs to be in the Language enum in "user.type.ts"
  public supportedLanguages: string[] = Object.values(Language);

  private currentUser = new BehaviorSubject<User | undefined>(undefined);
  user = this.currentUser.asObservable();

  constructor(private firebaseService: FirebaseService) {
    this.firestore = firebaseService.firestore;
    this.auth = firebaseService.getAuth();
    this.coll = collection(this.firestore, this.collectionName);

    setPersistence(this.auth, browserLocalPersistence);

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        onSnapshot(doc(this.coll, user.uid), (user) => {
          this.currentUser.next(user.data() as User);
        });
      }
    });
  }

  async createUser(user: User, uid: string): Promise<void> {
    await setDoc(doc(this.coll, uid), user);
  }

  async updateUserLanguage(uid: string, language: Language): Promise<void> {
    if (!language) return undefined;
    await updateDoc(doc(this.coll, uid), {
      ['language']: language,
    }).catch((error) => {
      throw new Error(`Error updating user language: ${error}`);
    });
  }

  async updateUserBirthDate(uid: string, timestamp: Timestamp): Promise<void> {
    const tempDate: Date = new Date();
    await updateDoc(doc(this.coll, uid), {
      ['birthDate']: timestamp ? timestamp : tempDate,
    });
  }

  async updateUserName(
    uid: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['firstName']: firstName ? firstName : 'invalid',
      ['lastName']: lastName ? lastName : 'invalid',
    });
  }

  async updateUserGender(uid: string, gender: Gender): Promise<void> {
    await updateDoc(doc(this.coll, uid), { ['gender']: gender });
  }

  async updateUserContinousGlucoseMonitoring(
    uid: string,
    toggle: boolean
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.continuousGlucoseMonitoring']: toggle,
    });
  }

  async updateUserInsulinPump(uid: string, toggle: boolean): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.insulinPump']: toggle,
    });
  }

  async updateUserPenType(uid: string, penType: 0.5 | 1): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.penType']: penType,
    });
  }

  async updateUserTreatmentType(
    uid: string,
    treatmentMode: TreatmentMode
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.treatmentMode']: treatmentMode,
    });
  }
  async updateUserDiabetesType(
    uid: string,
    diabetesType: DiabetesType
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.diabetesType']: diabetesType,
    });
  }
  async updateUserCorrectionFactor(
    uid: string,
    correctionFactor: number
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.correctionFactor']: correctionFactor,
    });
  }
  async updateUserMinimumLevel(
    uid: string,
    minimumLevel: number
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.minTarget']: minimumLevel,
    });
  }
  async updateUserMaximumLevel(
    uid: string,
    maximumLevel: number
  ): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.maxTarget']: maximumLevel,
    });
  }
  async updateUserTargetLevel(uid: string, targetLevel: number): Promise<void> {
    await updateDoc(doc(this.coll, uid), {
      ['diabetes_profile.target']: targetLevel,
    });
  }

  updateUser(uid: string, user: User): Promise<void> {
    try {
      setDoc(doc(this.coll, uid), user);
      return Promise.resolve();
    } catch (error) {

      return Promise.reject(error);
    }
  }

  async getUser(uid: string): Promise<User> {
    try {
      const docSnap = await getDoc(doc(this.coll, uid));

      if (docSnap.exists()) {
        return docSnap.data() as User;
      } else {
        throw new Error('User not found');
      }
    } catch (error) {
      throw new Error(`Error getting user: ${error}`);
    }
  }

  /**
   *
   * @param uid The user id
   * @returns  The user's ratios as an array of Ratio objects
   */
  async getUserAllRatios(uid: string): Promise<Ratio[] | undefined> {
    const userInfo = (await getDoc(doc(this.coll, uid))).data();
    if (userInfo) {
      return userInfo['ratios'];
    }
    return undefined;
  }

  async deleteUserRatio(uid: string) {
    await updateDoc(doc(this.coll, uid), {
      ratios: deleteField(),
    });
  }

  async updateUserRatios(uid: string, ratios: Ratio[]) {
    await updateDoc(doc(this.coll, uid), {
      ['ratios']: ratios,
    });
  }

  async updateUserRatio(uid: string, ratio: Ratio, index: number) {
    // Get current document
    const userDocRef = doc(this.coll, uid);
    const userDocSnap = await getDoc(userDocRef);

    // Get current ratios
    let ratios = userDocSnap.get('ratios');

    // Replace the ratio at the specified index
    ratios[index] = ratio;

    // Write back the entire array
    await updateDoc(userDocRef, { ratios: ratios });
  }

  //Get a specific ratio by the index
  async getUserRatio(uid: string, index: number): Promise<Ratio | undefined> {
    const userInfo = (await getDoc(doc(this.coll, uid))).data();
    if (userInfo) {
      return userInfo['ratios'][index];
    }
    return undefined;
  }

  //add a Ratio to the user's database
  async addUserRatio(uid: string, ratio: Ratio) {
    await updateDoc(doc(this.coll, uid), {
      ratios: arrayUnion(ratio),
    });
  }

  async getUserLanguage(uid: string): Promise<Language> {
    const userInfo = (await getDoc(doc(this.coll, uid))).data();
    if (userInfo) {
      if (this.supportedLanguages.includes(userInfo['language'])) {
        return userInfo['language'];
      }
    }
    return Language.FRENCH; // default language is french in the app!
  }
}
