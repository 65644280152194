import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseStorage, getStorage} from "firebase/storage"
import { Functions, connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { Auth, browserLocalPersistence, getAuth, indexedDBLocalPersistence, initializeAuth, onAuthStateChanged, setPersistence, User } from "firebase/auth";
import { environment } from 'src/environments/environment';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Capacitor } from '@capacitor/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  app: FirebaseApp;
  analytics: Analytics;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;

  user: User | null = null;

  constructor() {
    this.app = initializeApp(environment.firebase);
    this.analytics = getAnalytics(this.app);
    this.firestore = getFirestore(this.app);
    this.functions = getFunctions(this.app, "northamerica-northeast1");
    //connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
    this.storage = getStorage(this.app);
  }

  getAuth(): Auth {
    let auth: Auth;
    if (Capacitor.isNativePlatform()) {
      auth = initializeAuth(this.app, {
        persistence: indexedDBLocalPersistence,
      });
    } else {
      auth = getAuth(this.app);
    }
    return auth;
  }
}
